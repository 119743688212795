import { Button, Image, useColorModeValue } from '@chakra-ui/react'; // Import useColorModeValue
import { useWeb3Modal } from '@web3modal/wagmi/react';
import React from 'react';
import { useAccount } from 'wagmi';
import { shortenAddress } from '../utils/shortenAddress';
import { SiWalletconnect } from "react-icons/si";

export const ConnectWalletButton = ({ onClick }: { onClick?: () => void }) => {
  const { open } = useWeb3Modal();
  const { address, isConnecting } = useAccount();

  // Define light and dark text colors
  const lightTextColor = useColorModeValue('black', 'black');
  const darkTextColor = useColorModeValue('white', 'white');

  return (
    <Button
      onClick={() => {
        open();
        onClick?.();
      }}
      isLoading={isConnecting}
      loadingText="Connecting..."
      leftIcon={<SiWalletconnect color={useColorModeValue(lightTextColor, darkTextColor)} />}
      size={['md','lg']}
      bgGradient="linear(to-r, purple.300, purple.600)"
      _hover={{
        bgGradient: 'linear(to-r, purple.600, purple.300)',
      }}
      color={useColorModeValue(lightTextColor, darkTextColor)} // Apply light or dark text color
      colorScheme="purple"
      borderRadius="full"
    >
      {!address ? 'Connect Wallet' : shortenAddress(address)}
    </Button>
  );
};
