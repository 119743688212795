import {
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { ProjectName } from '../../constants/Config';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const MotionIcon = motion(Icon);
const MotionImage = motion(Image);

export const Header = () => {
  return (
    <Flex
      direction={['column', 'column', 'row']}
      justify={['center', 'center', 'space-between']}
      align={['center', 'center', 'center']}
      minH="95vh"
      py={[10, 10, 20]}
      px={[5, 5, 10]}
    >
      <Flex direction="column" gap={10} maxW={['100%', '100%', '50%']}>
        <Text
          fontSize={['8xl', '9xl', '10xl']}
          lineHeight={1}
          fontWeight={900}
          color="purple.500"
          textAlign={['center', 'center', 'left']}
          marginLeft={[0, 0, 20]}
          mt={0}
        >
          {ProjectName}
        </Text>
        <Heading
          maxW="35ch"
          textAlign={['center', 'center', 'left']}
          marginLeft={[0, 0, 20]}
        >
          100% Fully Decentralized Reward Distribution Protocol built on secured smart contracts.
        </Heading>
        <Flex direction={['column', 'column', 'row']} gap={4}>
          <Button
            size="lg"
            rightIcon={
              <MotionIcon
                animate={{
                  x: [5, 0, 5],
                  transition: {
                    repeat: Infinity,
                    duration: 1,
                  },
                }}
                as={ArrowForwardIcon}
              ></MotionIcon>
            }
            py={8}
            bgGradient="linear(to-r, purple.300, purple.600)"
            _hover={{
              bgGradient: 'linear(to-r, purple.600, purple.300)',
            }}
            borderRadius="full"
            as={Link}
            to="/register"
            w="100%"
            maxW={['100%', '100%', '250px']}
            mb={[4, 4, 0]}
            ml={[0, 0, 20]}
          >
            Register Now
          </Button>
          <Button
            size="lg"
            rightIcon={
              <MotionIcon
                animate={{
                  x: [5, 0, 5],
                  transition: {
                    repeat: Infinity,
                    duration: 1,
                  },
                }}
                as={ArrowForwardIcon}
              ></MotionIcon>
            }
            py={8}
            bgGradient="linear(to-r, purple.300, purple.600)"
            _hover={{
              bgGradient: 'linear(to-r, purple.600, purple.300)',
            }}
            borderRadius="full"
            as={Link}
            to="https://polygonscan.com/address/0xbebdcdd8d6e9962cff452fbab5c0711ec6989ef2#code" target='_blank'
            w="100%"
            maxW={['100%', '100%', '250px']}
            ml={[0, 0, 2]}
          >
            Smart Contract
          </Button>
        </Flex>
      </Flex>
      <MotionImage
        animate={{
          y: [5, 0, 5],
          x: [5, 0, 5],
          transition: {
            repeat: Infinity,
            duration: 1,
          },
        }}
        src="/header.png"
        maxW={['75%', '75%', '35%']}
        w="full"
        mt={[10, 0, 0]}
        mr={[0, 0, 20]}
      ></MotionImage>
    </Flex>
  );
};