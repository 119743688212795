'use client';

import { Box, Circle, Container, Flex, HStack, Icon, Stack, Text, VStack, Wrap, useColorMode, useColorModeValue, Link } from '@chakra-ui/react';
import { Logo } from './Logo';
import { FaDiscord, FaGithub, FaTelegramPlane, FaTwitter } from 'react-icons/fa';
import { IconType } from 'react-icons';
import { AiFillTwitterCircle } from 'react-icons/ai';

const SocialMediaIcons = ({ icon, link }: { icon: IconType; link?: string }) => {
  return (
    <Link href={link} isExternal>
      <Circle size={12} borderWidth="thin" borderColor="pink.500" cursor="pointer" _hover={{ borderWidth: 'thick' }}>
        <Icon as={icon} boxSize={4}></Icon>
      </Circle>
    </Link>
  );
};

export default function Footer() {
  return (
    <Box
      // bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}
      // borderTopRadius={[50, 75]}
      // borderColor="blue.200"
      borderTopWidth="2px"
      w="full"
    >
      <Container as={Stack} maxW={'6xl'} py={10}>
        <Wrap spacing={6}>
          <Stack spacing={6} w="max-content">
            <Flex direction="column" gap={3}>
              <Logo isFull={true}></Logo>
              <Text fontSize="lg" maxW={['30ch', '50ch']}>
                © 2024 GlobalFi. All rights reserved
              </Text>
            </Flex>
          </Stack>
          <Stack align={'flex-start'}>
            <HStack>
              <SocialMediaIcons icon={FaTelegramPlane} link="https://telegram.me/globalfiofficial"></SocialMediaIcons>
              <SocialMediaIcons icon={FaDiscord} link="https://discord.gg/FKMWYgVQ"></SocialMediaIcons>
            </HStack>
          </Stack>
        </Wrap>
      </Container>
    </Box>
  );
}