import { AspectRatio, Button, Heading, VStack } from '@chakra-ui/react';
import React from 'react';
import { supportedNetworkInfo } from '../../../../constants/Config';
import { useAccount, useChainId } from 'wagmi';
import { MainHeading } from '../../../../components/Dashboard/MainHeading';
import { FaArrowRight } from 'react-icons/fa';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { useState } from 'react';

export const Transactions = () => {
  const chainId = useChainId();
  const currentNetwork = supportedNetworkInfo[chainId];
  const { address } = useAccount();
  const [isHovered, setIsHovered] = useState(false);

  const polygonUrl = 'https://polygonscan.com';

  console.log(
    'Transactions Page',
    `${polygonUrl}/address/${address}`
  );
  return (
    <VStack spacing={10} >
      <MainHeading
        heading="Transactions"
        icon={FaArrowRight}
      ></MainHeading>
      <Button 
      marginBottom="310px"
      as="a"
      target="_blank"
      href={`${polygonUrl}/address/${address}`}
      rightIcon={<ExternalLinkIcon />}
      h={14}
      bgGradient={"linear(to-r, rgba(178,143,241, 0.8), rgba(109,72,194, 0.9))"}
      _hover={{ bgGradient: "linear(to-r, rgba(109,72,194, 0.9), rgba(178,143,241, 0.8))" }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      View transaction on Polygon
    </Button>

    </VStack>
  );
};
